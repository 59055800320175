<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-block card-stretch card-height">
          <div class="card-header d-flex justify-content-between">
            <div class="header-title">
              <h4 class="card-title mb-0">Rekapitulasi Perjalanan Dinas Dalam dan Luar Daerah</h4>
            </div>
          </div>
          <div class="card-body">
            <b-card>
              <div class="form-row">
                <b-col md="6" class="mb-3">
                  <label for="jenis_dinas">Jenis Perjalanan Dinas</label>
                  <v-select :options="jenis_dinas" v-model="filter.jenis_dinas" class="style-chooser">
                  </v-select>
                </b-col>
              </div>
              <div class="form-row">
                <b-col md="6" class="mb-3">
                  <label for="jenis_dinas">Tahun</label>
                  <v-select :options="tahun_laporan" v-model="filter.tahun_laporan" class="style-chooser">
                  </v-select>
                </b-col>
              </div>
              <button class="btn btn-primary" @click="handleSearch">Cari</button>
              <button class="btn btn-info mx-2" v-if="rows.length > 0" @click="exportHandle">Export Excel</button>
            </b-card>
            <div v-if="rows.length > 0">
              <vue-good-table 
                :columns="columns"
                :rows="rows"
                :line-numbers="true"
                :pagination-options="{
                  enabled: true,
                  mode: 'records',
                  perPage: 10,
                  position: 'top',
                  perPageDropdown: [5, 10, 20],
                  dropdownAllowAll: false,
                  setCurrentPage: 1,
                  nextLabel: 'Selanjutnya',
                  prevLabel: 'Sebelumnya',
                  rowsPerPageLabel: 'Jumlah baris',
                  ofLabel: 'dari',
                  pageLabel: 'halaman', // for 'pages' mode
                  allLabel: 'Semua',
                }"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'action'">
                    <b-button size="sm" @click="viewHandle(props)" variant="primary" class="mr-2" title="Edit">
                      <i class="dripicons dripicons-view-list"></i>
                    </b-button >
                  </span>
                  <span v-else>
                    {{props.formattedRow[props.column.field]}}
                  </span>
                </template>
              </vue-good-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import 'vue-good-table/dist/vue-good-table.css'
  import { VueGoodTable } from 'vue-good-table';
  import { postDataService } from '../../store/modules/crudservices'
  import config from '../../config/config'

  export default {
    name:'AnggaranList',
    data () {
      return {
        filter: {
          jenis_dinas: 'Semua',
          tahun_laporan: ''
        },
        tahun_laporan: [],
        jenis_dinas:['Semua', 'Dalam Daerah', 'Luar Daerah'],
        columns:[ {
            label: 'Nama',
            field: 'nama_pelaksana'
          }, {
            label: 'No. SPT',
            field: 'no_spt'
          }, {
            label: 'Asal',
            field: 'lok_asal'
          }, {
            label: 'Tujuan',
            field: 'lok_tujuan'
          }, {
            label: 'Tgl. Berangkat',
            field: 'tgl_berangkat'
          }, {
            label: 'Tgl. Kembali',
            field: 'tgl_kembali'
          }, {
            label: 'Jumlah Dibayarkan',
            field: 'uang_total',
            tdClass: 'text-right',
            formatFn: this.formatFn,
          },
        ],
        rows: [],
        form:{
          name: null
        }
      }
    },
    methods: {
      refreshLists(){
        const filter = this.filter
        postDataService('/report/spt/selesai', filter).then(data => {
          this.rows = data.data.data
        })
      },
      formatFn: function(value) {
        return Number(value).toLocaleString();
      },
      handleSearch() {
        const filter = this.filter
        const url = '/report/spt/selesai'
        postDataService(url, filter).then(response => {
          this.rows = response.data.data
        })
      },
      exportHandle(){
        // const url = `/report/spt/selesai/export`
        // getDataService(url).then(response => {
        //   let link = document.createElement("a")
        //   link.href = config.api_endpoint + response.data
        //   link.download = (new Date).toLocaleDateString() + "_sppd.pdf"
        //   link.target = "_blank"
        //   document.body.appendChild(link)
        //   link.click()
        //   document.body.removeChild(link)
        // })
        let url = `${config.api_endpoint}/report/spt/selesai/export?jenis_dinas=${this.filter.jenis_dinas}&tahun=${this.filter.tahun_laporan}`
        window.open(url, '_blank')
      },
      viewHandle(props) {
        let url = `/master/anggaran/edit/${props.row.id}`
        this.$router.push(url) 
      }
    },
    components: {
      VueGoodTable,
    },
    mounted() {
      const option = []
      let init_year = 2021;
      let year = (new Date()).getFullYear();
      while (init_year <= year) {
        option.push(init_year)
        init_year++;
      }
      this.tahun_laporan = option
      this.filter.tahun_laporan = year
      this.refreshLists()

    }
  }
</script>